.Home {
  overflow-x: hidden;
}

.banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-self: center;
  padding-bottom: 30px;
}

.bannerInfo {
  align-self: center;
  display: grid;
  z-index: 40;
  gap: 1em;
  padding-top: 200px;
  padding-bottom: 100px;
}

.bannerImage {
  width: 100%;
  height: 100%;
  padding-top: 100px;
}
.circleImage {
  width: 100%;
  height: 100%;
  background: url(/src/images/circleImage.png) center center no-repeat;
  background-size: contain;
}

.businessGas{
  width: 100%;
  height: 100%;
  background: url(/src/images/GasStock1.png) center center no-repeat;
  background-size: contain;
}
.businessElectricity{
  width: 100%;
  height: 100%;
  background: url(/src/images/ElectricityStock1.png) center center no-repeat;
  background-size: contain;
}
.businessTelecom{
  width: 100%;
  height: 100%;
  background: url(/src/images/TelecomStock1.png) center center no-repeat;
  background-size: contain;
}
.businessWater{
  width: 100%;
  height: 100%;
  background: url(/src/images/WaterStock1.png) center center no-repeat;
  background-size: contain;
}
.SMEbusiness{
  width: 100%;
  height: 100%;
  background: url(/src/images/smeCircle.png) center center no-repeat;
  background-size: contain;
}
.largeBusiness{
  width: 100%;
  height: 100%;
  background: url(/src/images/largeCircle.png) center center no-repeat;
  background-size: contain;
}

.bigText {
  text-align: left;
  font-size: 4em;
  line-height: 1;
}

.UtilityGallery {
  margin: 30px 0;
}

.smallText {
  text-align: left;
  font-size: 1.1em;
}

#tsparticles {
  z-index: -1;
}

.contact {
  width: min-content;
  padding: 10px 0;
  align-self: flex-start;
  white-space: nowrap;
}

.redCircle {
  width: 28px;
  height: 28px;
}

.Headings {
  font-size: 1.7em;
  font-weight: 700;
  text-align: center;
  color: #000;
  display: block;
}

.ServicesTitle {
  font-size: 0.9em;
  text-align: center;
  align-self: center;
  width: 70%;
}

.CircleIcons {
  padding: 20px;
  width: fit-content;
  background-color: #ffffff;
  border-radius: 50%;
  margin-bottom: 1em;
}

.SmallCircleIcons {
  padding: 10px;
  width: fit-content;
  background-color: #ffffff;
  border-radius: 50%;
  margin-bottom: 1em;
}

.bggas{
  background-image: url("../../images/businessGas2.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 100%;
}
.bgelectricty{
  background-image: url("../../images/businessElectricity2.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 100%;
}
.bgtelecom{
  background-image: url("../../images/businessTelecom2.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 100%;
}
.bgwater{
  background-image: url("../../images/businessWater2.jpg");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  height: 100%;
}

.Services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    41deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(240, 245, 250, 1) 0%,
    rgba(235, 244, 252, 1) 100%
  );
  z-index: 9999;
  padding: 30px 30px;
  color: #4b5563;
}
.Switching{
  background-color: #0A1A3A;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 0px 30px 30px 30px;
  color: #fcfcfc;
}

.ServicesHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ServicesBrief {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  margin-top: 50px;
  row-gap: 4em;
  column-gap: 2em;
  width: 80%;
  align-self: center;
}

.SwitchingBrief{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 4em;
  row-gap: 4em;
  margin: 30px 0px 0px 0;
  padding: 0px 20px 50px 0;
  width: 100%;
}

.Headings {
  display: flex;
  flex-direction: column;
  line-height: 1.2em;
  min-height: 80px;
  justify-content: center;
}

.briefHeadings h1 {
  color: #00b3e8;
  font-size: 0.9em;
  font-weight: 500;
  text-align: center;
}

.briefHeadings p {
  font-size: 0.85em;
  text-align: center;
  color: #4b5563;
}

.band {
  width: 100%;
  height: 60px;
  background-color: #aee2ff;
}

.briefs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Cardbriefs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 170px;
  min-width: 200px;
}

.menu-link {
  border-bottom: 1px solid #000929;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 10px 20px 10px;
  margin: 0 20px;
  color: #627193;
  font-weight: 500;
  font-size: 1.05em;
}

.accordion-link{
  color: #627193;
  font-weight: 500;
  font-size: 1.05em;
}

.drop-link{
  padding: 10px;
}
.drop-link:hover{
  text-decoration: underline;
}

/* css for utility companies */

.utilityCompanies {
  background-color: #fff;
  padding: 30px 0 30px 0;
  display: none;
  flex-direction: column;
}
.utilityCompanies p,
.Sectors p {
  font-size: 0.9em;
  text-align: center;
  padding-bottom: 20px;
}

/* Css for Our Process */

.ourProcess {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2em;
  padding: 60px 0 40px 0;
}

.ourProcess img {
  height: 80vh;
  margin-bottom: 50px;
}

.ProcessImg img {
  height: 80vh;
}

.ProcessBriefs {
  width: 40%;
}

.ProcessBriefs p {
  font-size: 0.9em;
}

.Process {
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.ProcessCards {
  display: flex;
  flex-direction: column;
}

.ProcessCards h1 {
  font-size: 0.9em;
  font-weight: 500;
  color: #00b3e8;
}

.Process p,
.WhyUsPara p,
.Feedback p {
  font-size: 0.85em;
  color: #4b5563;
}

.Process img {
  height: 40px;
}

/* CSS for Sectors */

.Sectors {
  background-color: #fff;
  padding: 10px 0 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SectorsHeader {
  width: 90%;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SectorsHeader p {
  width: 60%;
  align-self: center;
}

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 4em;
  flex-wrap: wrap;
  align-self: center;
  margin: 0 20px;
}

.SectorCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 30%;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.SectorCard h1 {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;
  background: #00b3e8;
  margin: 0 0 20px 0;
  width: 100%;
  border-radius: 10px 10px 0 0;
  padding: 30px 0;
}

.SectorCard p {
  padding: 0 20px 20px 20px;
}

.cardP {
  font-size: 0.85em;
  color: #4b5563;
}

.statement {
  flex-wrap: nowrap;
  background-color: #0a1a3a;
  color: #fff;
}

.statement h1 {
  border-right: 2px solid;
  border-color: rgb(219, 219, 219);
}

/* Why us */

.WhyUs {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  justify-content: center;
  padding: 50px 0;
  column-gap: 5em;
  height: auto;
  border-bottom: 1px solid rgb(219, 219, 219);
}

.WhyUs img {
  height: 70vh;
}

.button {
  background-color: #00b3e8;
  border-radius: 18px;
  color: #fff;
  width: fit-content;
  padding: 10px 20px;
  cursor: pointer;
}

.WhyUsPara {
  width: 20%;
  height: auto;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.ratings svg {
  display: inline !important;
  width: 15px;
  height: 15px;
}

.Feedback {
  background-color: #eef5fc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 20px;
}

.feedbackp {
  width: 50%;
  align-self: center;
}

.FeedbackCardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  gap: 2em;
  margin-top: 20px;
  width: 50%;
  align-self: center;
  padding-left: 40px;
}

.FeedbackCards h1 {
  color: #00b3e8;
  font-size: 0.9em;
  font-weight: 500;
}

.FeedbackCards {
  border: 1px solid #cedce9;
  border-radius: 4px;
  padding: 10px;
  background-color: #fff;
  width: 300px;
}

.FAQ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  align-content: center;
  padding: 50px 20px;
}

.FAQp {
  width: 70%;
  align-self: center;
  text-align: center;
  font-size: 0.85em;
  color: #4b5563;
}

.Footer {
  background-color: #000929;
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  justify-content: space-between;
  color: #fff;
  gap: 5em;
  padding: 50px 20px 20px 20px;
}

.FootBlocks {
  margin-bottom: 20px;
}

.FootLeft {
  display: flex;
  flex-direction: column;
  max-width: 350px;
}

.Foot a {
  cursor: pointer;
  display: block;
}

.Foot {
  display: flex;
  flex-direction: column;
  row-gap: 0.1em;
}

.FootLeft img {
  width: 160px;
}

.contactusP {
  display: flex;
  flex-direction: row;
}

.contactusP p {
  margin-top: -5px;
  padding-left: 5px;
}

.Foot h1,
.FootLeft h1 {
  margin-bottom: 10px;
  font-size: 1em;
  font-weight: 500;
  color: #fff;
}

.Foot a,
.FootLeft a {
  font-size: 0.85em;
  margin: 0;
  padding-bottom: 5px;
}

.AboutBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 120px !important;
  height: 45px !important;
  border-radius: 10px;
}

.copyright {
  font-size: 0.8em;
}

@media screen and (max-width: 620px) {
  .banner {
    grid-template-columns: 1fr;
  }
  .bannerImage {
    display: none;
  }
  .ServicesBrief {
    grid-template-columns: 1fr;
    column-gap: .5em;
  }
  .Headings {
    line-height: 1em;
    min-height: 120px;
  }
  .ServicesTitle {
    width: 99%;
  }
}

@media screen and (max-width: 1020px) {
  .ServicesTitle {
    width: 99%;
  }
  .FeedbackCardContainer {
    padding-left: 5px;
  }
}


@media screen and (max-width: 860px) {
  .SwitchingBrief{
    column-gap: 4em;
  }
  .ourProcess {
    padding-top: 40px;
    flex-direction: column;
    align-items: center;
  }
  .ProcessImg img {
    height: auto;
  }

  .ourProcess img {
    padding: 0px 20px 0 20px;
    margin-bottom: 0;
  }

  .Process img {
    display: block;
  }
  .ProcessBriefs {
    width: 60%;
  }
  .statement {
    flex-wrap: wrap;
  }
  .statement h1 {
    border-right: none;
    border-bottom: 2px solid;
    border-color: rgb(219, 219, 219);
    padding-bottom: 20px;
  }
  .statement p {
    padding-top: 20px;
  }
  .feedbackp {
    width: 70%;
    align-self: center;
  }
}

@media screen and (min-width: 1020px) and (max-width: 1400px) {
  .bigText {
    font-size: 3.5em;
  }
  .bannerInfo {
    gap: 0.5em;
  }
  .WhyUs {
    column-gap: 3em;
  }
  .WhyUsPara {
    width: 30%;
  }
}

@media screen and (min-width: 820px) and (max-width: 1020px) {
  .bannerImage {
    justify-self: right;
  }
  .banner {
    padding-bottom: 0px;
  }
  .bannerInfo {
    gap: 0.5em;
  }
  s .contact {
    align-self: flex-end;
  }
  .bigText {
    font-size: 3em;
    align-self: center;
  }
  .smallText {
    align-self: flex-start;
  }
  .WhyUs {
    column-gap: 3em;
  }
  .WhyUs img {
    height: 50vh;
  }
  .WhyUsPara {
    width: 30%;
  }
  .FeedbackCards {
    width: 300px;
  }
  .SectorCard {
    width: 35%;
  }
}

@media screen and (min-width: 720px) and (max-width: 820px) {
  .bigText {
    font-size: 3em;
    align-self: center;
  }
  .highlighted {
    font-size: 0.9em;
  }
  .redCircle {
    width: 25px;
    height: 25px;
  }
  .banner {
    padding-bottom: 50px;
  }
  .bannerImage {
    padding-top: 0;
    justify-self: right;
  }
  .bannerInfo {
    padding-top: 0;
    padding-bottom: 0;
  }
  .SectorsHeader p {
    width: 80%;
  }
  .WhyUs {
    column-gap: 2em;
  }
  .WhyUs img {
    height: 50vh;
  }
  .WhyUsPara {
    width: 40%;
  }
  .FeedbackCards {
    width: 300px;
  }
  .FAQp {
    width: 80%;
  }
  .card-container {
    gap: 2em;
  }
  .SectorCard {
    width: 40%;
  }
}

@media screen and (min-width: 620px) and (max-width: 720px) {
  .bigText {
    font-size: 2.8em;
    align-self: center;
  }
  .highlighted {
    font-size: 0.8em;
  }
  .smallText {
    font-size: 0.9em;
  }
  .redCircle {
    width: 20px;
    height: 20px;
  }
  .contact {
    font-size: 0.8em;
  }
  .bannerImage {
    padding-top: 0px;
    justify-self: right;
  }
  .bannerInfo {
    padding-top: 0;
    padding-bottom: 0;
  }
  .space {
    height: 70px;
  }
  .ProcessBriefs {
    width: 60%;
  }
  .SectorsHeader p {
    width: 80%;
  }
  .WhyUs {
    flex-direction: column;
    align-items: center;
  }
  .WhyUs img {
    padding-bottom: 40px;
  }
  .WhyUsPara {
    width: 80%;
  }
  .FeedbackCards {
    width: 250px;
  }
  .FAQp {
    width: 90%;
  }
  .feedbackp {
    width: 90%;
  }
  .card-container {
    gap: 2em;
  }
  .SectorCard {
    width: 40%;
  }
}

@media screen and (min-width: 420px) and (max-width: 620px) {
  .bigText {
    font-size: 3.5em;
  }
  .highlighted {
    font-size: 1em;
  }
  .redCircle {
    width: 27px;
    height: 27px;
  }
  .smallText {
    font-size: 1em;
  }
  .contact {
    font-size: 1em;
  }
  .space {
    height: 50px;
  }
  .bannerInfo {
    padding-top: 50px;
    padding-bottom: 25px;
  }
  .ProcessBriefs {
    width: 80%;
  }
  .SectorsHeader p {
    width: 90%;
  }
  .WhyUs {
    flex-direction: column;
    align-items: center;
  }
  .WhyUs img {
    padding: 0px 20px 40px 20px;
    height: auto;
  }
  .WhyUsPara {
    width: 80%;
  }
  .FeedbackCards {
    width: 350px;
  }
  .FeedbackCardContainer {
    grid-template-columns: none;
    grid-template-rows: 1fr;
  }
  .FAQp {
    width: 95%;
  }
  .feedbackp {
    width: 95%;
  }
  .Footer {
    grid-template-columns: 1fr;
    gap: 0em;
  }
  .card-container {
    gap: 2em;
  }
  .SectorCard {
    width: 90%;
  }
}

@media screen and (min-width: 350px) and (max-width: 420px) {

  .bigText {
    font-size: 3.5em;
  }
  .highlighted {
    font-size: 1em;
  }
  .redCircle {
    width: 27px;
    height: 27px;
  }
  .smallText {
    font-size: 1em;
  }
  .contact {
    font-size: 1em;
  }
  .space {
    height: 50px;
  }
  .bannerInfo {
    padding-top: 0px;
    padding-bottom: 25px;
  }
  .ProcessBriefs {
    width: 85%;
  }
  .SectorsHeader p {
    width: 95%;
  }
  .WhyUs {
    flex-direction: column;
    align-items: center;
  }
  .WhyUs img {
    padding: 0px 20px 40px 20px;
    height: auto;
  }
  .WhyUsPara {
    width: 80%;
  }
  .FeedbackCards {
    width: 300px;
  }
  .FeedbackCardContainer {
    grid-template-columns: none;
    grid-template-rows: 1fr;
  }
  .FAQp {
    width: 95%;
  }
  .feedbackp {
    width: 95%;
  }
  .Footer {
    grid-template-columns: 1fr;
    gap: 0em;
  }
  .card-container {
    gap: 2em;
  }
  .SectorCard {
    width: 90%;
  }
  .banner {
    height: 80vh;
  }
}

@media screen and (max-width: 350px) {
  .bigText {
    font-size: 2.5em;
  }
  .highlighted {
    font-size: 0.8em;
  }
  .redCircle {
    width: 20px;
    height: 20px;
  }
  .smallText {
    font-size: 0.8em;
  }
  .contact {
    font-size: 0.8em;
  }
  .bannerInfo {
    padding-top: 0px;
    padding-bottom: 25px;
  }
  .ProcessBriefs {
    width: 90%;
  }
  .SectorsHeader p {
    width: 95%;
  }
  .WhyUs {
    flex-direction: column;
    align-items: center;
  }
  .WhyUs img {
    padding: 0px 20px 40px 20px;
    height: auto;
  }
  .WhyUsPara {
    padding-top: 20px;
    width: 80%;
  }
  .FeedbackCards {
    width: 250px;
  }
  .FeedbackCardContainer {
    grid-template-columns: none;
    grid-template-rows: 1fr;
  }
  .feedbackp {
    width: 95%;
  }
  .Footer {
    grid-template-columns: 1fr;
    gap: 0em;
  }
  .card-container {
    gap: 2em;
  }
  .SectorCard {
    width: 90%;
  }
  .banner {
    height: 80vh;
  }
}

@media screen and (min-height: 520px) and (max-height: 640px) {
  .space {
    height: 50px;
  }
  .bannerImage {
    padding-top: 20;
  }
}

@media screen and (max-height: 520px) {
  .space {
    height: 10px;
  }
  .bannerInfo {
    padding-top: 120px;
    padding-bottom: 30px;
  }
}
