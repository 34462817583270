h2{
    font-weight: 600;
    font-size: 1.1rem;
    margin: 20px 0;
}
h1{
    font-weight: 600;
    font-size: 1.2rem;
    margin: 20px 0;
}
p{
margin: 5px 0;
}
ul{
    margin: 20px 0;
}
li{
    margin: 5px 0;
    list-style:disc;
    margin-left: 40px;
}
h3, h4{
    font-weight: 600;
    font-size: .9rem;
    margin: 20px 0;
}