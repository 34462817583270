@tailwind base;
@tailwind components;
@tailwind utilities;

.css-11xur9t-MuiPaper-root-MuiTableContainer-root, .MuiPaper-elevation1{
  box-shadow: none !important;
}
.MuiTableCell-root{
  border-bottom: none !important;
}
.MuiAccordion-rounded:last-child{
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00b3e8;
}

.bn632-hover {
  width: 160px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  height: 45px;
  text-align: center;
  border: none;
  background-size: 300% 100%;
  border-radius: 10px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.bn632-hover:hover {
  background-position: 100% 0;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.bn632-hover:focus {
  outline: none;
}

.bn632-hover.bn26 {
  background: #00b3e8;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to left bottom, #ebf4fd, #f4f6f9);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* card style */
.a-box {
  display: inline-block;
  width: 240px;
  text-align: center;
}

.img-container {
  height: 200px;
  width: 200px;
  overflow: hidden;
  border-radius: 0px 0px 20px 20px;
  display: inline-block;
}

.img-container img {
  width: 300px;
  height: 170px;
  margin: 0px 0px 0px 0px;
}

.inner-skew {
  display: inline-block;
  border-radius: 20px;
  overflow: hidden;
  padding: 0px;
  font-size: 0px;
  margin: 30px 0px 0px 0px;
  background: #c8c2c2;
  height: 250px;
  width: 200px;
}

.text-container {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 120px 20px 20px 20px;
  border-radius: 20px;
  background: #fff;
  margin: -120px 0px 0px 0px;
}

.text-container h3 {
  margin: 20px 0px 10px 0px;
  color: #00b3e8;
  font-size: 0.9em;
}
/* end card styles */

.link {
  cursor: pointer;
  color: #627193;
  font-weight: 500;
  font-size: 1.05em;
}

.link:hover {
  color: #00b3e8;
}
.active {
  color: #00b3e8;
}

.navigation {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #eef5fc;
  position: fixed;
  top: 0;
  z-index: 50;
}

.services-dropdown,
.sectors-dropdown {
  position: absolute;
  padding: 50px;
  top: 100px;
  right: 0;
  z-index: 999;
  height: 250px;
  width: 820px;
  background-color: #000929;
  display: flex;
  flex-direction: row;
  gap: 4em;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

.logo-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 50;
  height: 100px;
  width: 100%;
  padding: 0px 20px;
  align-items: center;
}

.hamburger {
  cursor: pointer;
}

.Menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
}

@media screen and (max-width: 420px) {
  .Logo {
    width: 40%;
  }
}

@media screen and (min-width: 420px) and (max-width: 620px) {
  .Logo {
    width: 30%;
  }
}

@media screen and (min-width: 620px) and (max-width: 720px) {
  .Logo {
    width: 25%;
  }
}

@media screen and (min-width: 720px) and (max-width: 820px) {
  .Logo {
    width: 20%;
  }
  .logo-menu {
    height: 100px;
  }
  .space {
    height: 70px;
  }
}

@media screen and (min-width: 820px) {
  .Logo {
    width: 150px;
  }
  .logo-menu {
    height: 100px;
  }
}

@media screen and (min-width: 1220px) {
  .Menu {
    width: 50%;
  }
}

@media screen and (min-width: 1020px) and (max-width: 1220px) {
  .Menu {
    width: 60%;
  }
}
